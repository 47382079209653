import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import cn from 'classnames';
import CopyBlock from '../../CopyBlock';
import QuoteMarks from '../../../images/vectors/quotes.svg';
import Twitter from '../../../icons/Twitter.svg';
import QuoteProvider from '../../CopyBlock/QuoteProvider';

export default function CustomQuoteBlock({ quoteText, person, vertical, position }) {
	const {
		personName,
		personImage,
		personPosition,
		twitterHandle: personTwitterHandle,
		twitterLink,
	} = person;

	return (
		<QuoteProvider>
			<div className="grid md:grid-cols-2 grid-cols-1 gap-4  pt-12 items-center justify-center">
				{position === 'left' && (
					<div className="relative mx-3 ">
						<img
							src={QuoteMarks}
							className={cn('', {
								'mb-4 w-14': vertical,
								'absolute hidden lg:block top-1 -left-20': !vertical,
							})}
							alt="Quotation vector"
						/>
						<CopyBlock>{quoteText.childMdx.body}</CopyBlock>
						<p
							className={cn('font-bold last:mb-0 text-brand-300', {
								'mt-4 mb-1 text-2xl': vertical,
								'mb-4 text-xl': !vertical,
							})}
						>
							{personName}
						</p>
						<p
							className={cn('w-full', {
								'mb-2 text-base text-brand-400': vertical,
								'mb-4 font-bold': !vertical,
							})}
						>
							<span>{personPosition}</span>
						</p>
						<div
							className={cn('flex items-center', {
								'pb-0': vertical,
								'pb-4': !vertical,
							})}
						>
							<Twitter width="30" alt="twitter icon" className="text-brand-200" />
							<a
								className="ml-3 text-sm font-bold font-sm text-brand-200 sm:inline-block"
								href={twitterLink}
								target="_blank"
								rel="noreferrer"
							>
								{personTwitterHandle}
							</a>
						</div>
					</div>
				)}
				<div
					className={`flex ${
						position === 'left'
							? 'items-center justify-end'
							: 'items-start justify-start hidden md:block'
					}`}
				>
					<GatsbyImage image={personImage.gatsbyImageData} alt={personImage.alt || ''} />
				</div>
				{position === 'right' && (
					<div className="relative mx-3">
						<img
							src={QuoteMarks}
							className={cn('', {
								'mb-4 w-14': vertical,
								'absolute hidden lg:block top-1 -left-20': !vertical,
							})}
							alt="Quotation vector"
						/>
						<CopyBlock>{quoteText.childMdx.body}</CopyBlock>
						<p
							className={cn('font-bold last:mb-0 text-brand-300', {
								'mt-4 mb-1 text-2xl': vertical,
								'mb-4 text-xl': !vertical,
							})}
						>
							{personName}
						</p>
						<p
							className={cn('', {
								'mb-2 text-base text-brand-400': vertical,
								'mb-4 font-bold': !vertical,
							})}
						>
							<span>{personPosition}</span>
						</p>

						<div
							className={cn('flex items-center', {
								'pb-0': vertical,
								'pb-4': !vertical,
							})}
						>
							<Twitter width="30" alt="twitter icon" className="text-brand-200" />
							<a
								className="ml-3 text-sm font-bold font-sm text-brand-200 sm:inline-block"
								href={twitterLink}
								target="_blank"
								rel="noreferrer"
							>
								{personTwitterHandle}
							</a>
						</div>
					</div>
				)}
				{position === 'right' && (
					<div
						className={`flex ${
							position === 'left'
								? 'items-center justify-end'
								: 'items-start justify-start md:hidden block'
						}`}
					>
						<GatsbyImage
							image={personImage.gatsbyImageData}
							alt={personImage.alt || ''}
						/>
					</div>
				)}
			</div>
		</QuoteProvider>
	);
}
