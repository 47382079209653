import React, { useRef } from 'react';
import cn from 'classnames';
import Container from '../../Container';
import CopyBlock from '../../CopyBlock';

const ImageWithTex = ({ imgSrc, imagePosition, text, bgColor, className, alt }) => {
	const imgRef = useRef();
	return (
		<section
			className={cn(`last:mb-0`, {
				'bg-white text-brand-400': bgColor === 'White' || '',
				' bg-secondary-100': bgColor === 'Grey',
				' bg-secondary-200 text-white ': bgColor === 'Black',
			})}
		>
			<Container className="!py-0 !px-0">
				<div
					className={cn(`${className}  md:mb-12 flex lg:flex-row md:pt-10`, {
						'flex-col-reverse gap-4': imagePosition === 'Right',
						'flex-col gap-4': imagePosition === 'Left',
					})}
				>
					{imagePosition === 'Left' && (
						<div className="w-full h-full mt-0 md:ml-[-180px] lg:mt-[-45px]">
							<img
								ref={imgRef}
								src={imgSrc}
								alt={alt}
								className="w-full h-full object-cover"
							/>
						</div>
					)}
					<div
						className={cn(` md:px-6 px-3 w-full lg:my-8 md:pr-8 pb-6 lg:w-full`, {
							'lg:mr-6 lg:border-l-2 border-brand-200': imagePosition === 'Right',
							'lg:ml-6': imagePosition === 'Left',
						})}
					>
						<CopyBlock>{text}</CopyBlock>
					</div>
					{imagePosition === 'Right' && (
						<div className="w-full h-auto mt-0 lg:mr-[-127px] xl:mr-[-180px]  lg:mt-[-45px]">
							<img
								ref={imgRef}
								src={imgSrc}
								alt={alt}
								className="w-full h-full object-cover"
							/>
						</div>
					)}
				</div>
			</Container>
		</section>
	);
};

export default ImageWithTex;
