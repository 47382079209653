import React from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { nanoid } from 'nanoid';
import DefaultLayout from '../layouts/Default';
import DatoSEO from '../components/DatoSEO';
import Hero from '../components/CMSBlocks/Hero';
import ImageWithText from '../components/CMSBlocks/ImageWithText';
import OrderListBlock from '../components/CMSBlocks/OrderListBlock';
import CustomQuoteBlock from '../components/CMSBlocks/CustomQuoteBlock';
import CharityCarouselBlock from '../components/CMSBlocks/CharityCarouselBlock';
import NewsCard from '../components/NewsCard';

const CharityPage = ({ data }) => {
	const location = useLocation();
	const {
		seoMetaTags,
		hero,
		imageTextBlock,
		quote,
		textWithBulletContainer,
		quoteRight,
		imagePositionRight,
		imagePosition,
		imageTextBlockLeft,
		imageBanner,
		charityCarouselContainer,
		slug,
	} = data.allDatoCmsCharityPage.nodes[0];
	const allCharityPressRelease = [...data.allDatoCmsPressRelease.nodes];
	const rightQuote = {
		personName: quote[0].personName,
		personPosition: quote[0].personPosition,
		twitterHandle: quote[0].twitterHandle,
		twitterLink: quote[0].twitterLink,
		personImage: quote[0].personImage,
	};
	const leftQuote = {
		personName: quoteRight[0].personName,
		personPosition: quoteRight[0].personPosition,
		twitterHandle: quoteRight[0].twitterHandle,
		twitterLink: quoteRight[0].twitterLink,
		personImage: quoteRight[0].personImage,
	};
	return (
		<div>
			<DatoSEO path={location.pathname} meta={seoMetaTags} />
			{hero.map((el) => (
				<div key={nanoid()}>
					<Hero
						bgImg={el.backgroundImage.url}
						title={el.heroTitle}
						content={el.heroTextNode.childMdx.body}
					/>
				</div>
			))}
			{imageTextBlock.map((el) => (
				<ImageWithText
					key={nanoid()}
					imgSrc={el.imageWithCopyImage.url}
					alt={el.imageWithCopyImage.alt}
					text={el.textNode.childMdx.body}
					imagePosition={el.imagePosition}
					bgColor={el.backgroundColour}
				/>
			))}

			<section className="bg-brand-500 md:mt-[-55px]">
				<div className="container px-0 md:mx-auto overflow-auto md:px-6">
					<CustomQuoteBlock
						quoteText={quote[0].quoteTextNode}
						person={rightQuote}
						position={imagePosition}
					/>
				</div>
			</section>

			<section className="bg-white py-10 mb-10">
				<div className="container px-3 mx-auto overflow-visible md:px-6">
					{textWithBulletContainer.map((el) => (
						<div className="flex flex-col gap-4" key={nanoid()}>
							<h1 className="text-3xl font-bold ">{el.title}</h1>
							<div>{el.subTitle}</div>
							<OrderListBlock el={el} />
						</div>
					))}
				</div>
			</section>
			<section className="bg-brand-500 md:mt-[-55px]">
				<div className="container px-0 md:mx-auto overflow-visible md:px-6">
					<CustomQuoteBlock
						quoteText={quoteRight[0].quoteTextNode}
						person={leftQuote}
						position={imagePositionRight}
						showWriteUp={false}
					/>
				</div>
			</section>
			<section className="flex items-start justify-center object-cover">
				{imageBanner.map((image) => (
					<img
						key={nanoid()}
						src={image.image.url}
						alt={image.image.alt || ''}
						className="object-cover w-full h-auto"
					/>
				))}
			</section>

			{imageTextBlockLeft.map((el) => (
				<ImageWithText
					key={nanoid()}
					imgSrc={el.imageWithCopyImage.url}
					alt={el.imageWithCopyImage.alt}
					text={el.textNode.childMdx.body}
					imagePosition={el.imagePosition}
					bgColor={el.backgroundColour}
				/>
			))}
			<section className="flex items-start justify-center py-10  ">
				{charityCarouselContainer?.map((charity) => (
					<CharityCarouselBlock key={charity.id} data={charity} />
				))}
			</section>
			<section className="bg-secondary-200">
				{allCharityPressRelease.length > 0 && (
					<div className="max-w-5xl px-4 py-16 m-auto lg:pb-32 xxl:max-w-6xl">
						<h3 className="mb-5 text-4xl font-normal text-center text-white">
							Press office
						</h3>
						<div className="block grid-cols-3 gap-3 lg:grid">
							{allCharityPressRelease
								.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
								.map((newsItem) => (
									<div className="mb-4 bg-white" key={nanoid()}>
										<NewsCard
											key={nanoid()}
											newsItem={newsItem}
											type="charity"
											parentSlug={slug}
										/>
									</div>
								))}
						</div>
					</div>
				)}
			</section>
		</div>
	);
};
export const query = graphql`
	query charityPageQuery {
		allDatoCmsCharityPage {
			nodes {
				id
				title
				slug
				locale
				seoMetaTags {
					...GatsbyDatoCmsSeoMetaTags
				}
				hero: header {
					... on DatoCmsHero {
						heroTextNode {
							childMdx {
								body
							}
						}
						backgroundImage {
							url
						}
						heroTitle
					}
				}
				charityCarouselContainer {
					header
					subHeader
					id
					imageBlock {
						id
						location
						charityLink
						image {
							alt
							url
							gatsbyImageData(
								layout: CONSTRAINED
								width: 200
								imgixParams: { fm: "webp", auto: "compress" }
							)
						}
					}
				}
				imageTextBlock {
					backgroundColour
					imagePosition
					id
					text
					imageWithCopyImage {
						url
						alt
					}
					textNode {
						childMdx {
							body
						}
					}
				}
				imageTextBlockLeft {
					backgroundColour
					imagePosition
					id
					text
					imageWithCopyImage {
						url
						alt
					}
					textNode {
						childMdx {
							body
						}
					}
				}
				imagePositionRight
				imagePosition
				imageBanner {
					image {
						gatsbyImageData(
							layout: CONSTRAINED
							imgixParams: { auto: "compress" }
							height: 463
							width: 1440
						)
						alt
						url
					}
				}
				quote {
					id
					personName
					personPosition
					twitterHandle
					twitterLink
					personImage {
						gatsbyImageData(
							layout: CONSTRAINED
							imgixParams: { auto: "compress" }
							height: 580
							width: 580
						)
					}
					quoteTextNode {
						childMdx {
							body
						}
					}
				}
				textWithBulletContainer {
					title
					subTitle
					rightList
					leftList
					rightListNode {
						childMdx {
							body
						}
					}
					leftListNode {
						childMdx {
							body
						}
					}
				}
				quoteRight {
					id
					quoteTextNode {
						childMdx {
							body
						}
					}
					personName
					personPosition
					twitterHandle
					twitterLink
					personImage {
						gatsbyImageData(
							layout: CONSTRAINED
							imgixParams: { auto: "compress" }
							height: 580
							width: 580
						)
					}
				}
			}
		}
		allDatoCmsPressRelease(filter: { pressType: { eq: "Charity" } }, limit: 6) {
			nodes {
				title
				releaseDate
				id
				articleMainImage {
					alt
					url
				}
				pressType
				isArticleInternal
				externalArticleLink
				tags
				slug
			}
		}
	}
`;
const WrappedCharityPage = (props) => (
	<DefaultLayout fullWidth>
		<CharityPage {...props} />
	</DefaultLayout>
);

export default WrappedCharityPage;
