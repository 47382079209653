import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import defaultProviderComponents from './defaultProviderComponents';

export default function OrderListProvider({ children }) {
	return (
		<MDXProvider
			components={{
				...defaultProviderComponents,
				ol: (props) => <ol className="list-decimal pl-6" {...props} />,
				li: (props) => <li className="mb-2 text-md" {...props} />,
				ul: (props) => <ul className="list-disc pl-5" {...props} />,
			}}
		>
			{children}
		</MDXProvider>
	);
}
