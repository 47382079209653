import React from 'react';
import OrderListProvider from '../../CopyBlock/OrderListProvider';
import CopyBlock from '../../CopyBlock';

export default function OrderListBlock({ el }) {
	return (
		<div className="grid md:grid-cols-2 grid-cols-1">
			<div className="col-span-1">
				<OrderListProvider>
					<CopyBlock>{el.leftListNode.childMdx.body}</CopyBlock>
				</OrderListProvider>
			</div>
			<div className="col-span-1">
				<OrderListProvider>
					<CopyBlock>{el.rightListNode.childMdx.body}</CopyBlock>
				</OrderListProvider>
			</div>
		</div>
	);
}
