import React from 'react';

export default function Carousel({ data }) {
	const [currentIndex, setCurrentIndex] = React.useState(0);
	const carousel = React.useRef(null);
	const movePrev = React.useCallback(() => {
		if (currentIndex > 0) {
			setCurrentIndex((prevState) => (prevState === 0 ? data.length - 1 : prevState - 1));
		}
	}, [currentIndex, data.length]);
	const moveNext = React.useCallback(() => {
		if (currentIndex < data.length - 1) {
			setCurrentIndex((prevState) => (prevState === data.length - 1 ? 0 : prevState + 1));
		}
	}, [currentIndex, data.length]);
	React.useEffect(() => {
		if (carousel.current) {
			carousel.current.scrollTo({
				left: currentIndex * carousel.current.offsetWidth,
				behavior: 'smooth',
			});
		}
	}, [currentIndex]);
	return (
		<div className="carousel my-1 mx-auto">
			<div className="relative overflow-x-scroll no-scrollbar">
				<div
					ref={carousel}
					className="carousel-container relative flex  gap-4 overflow-hidden scroll-smooth snap-x snap-mandatory touch-pan-x z-10"
				>
					{data.map((resource) => (
						<div
							key={resource?.id}
							className="carousel-item text-center relative w-44 h-44 snap-start border border-[#C4C4C4] p-3"
						>
							<a
								href={resource?.charityLink}
								target="_blank"
								rel="noreferrer"
								className="h-full w-full aspect-square block bg-origin-padding bg-center bg-contain bg-no-repeat z-0"
								style={{ backgroundImage: `url(${resource?.image.url || ''})` }}
							>
								<img
									src={resource?.image.url || ''}
									alt={resource?.image.alt || ''}
									className="w-full aspect-square hidden"
								/>
							</a>
						</div>
					))}
				</div>
			</div>
			<div
				className={`${
					data.length < 10 ? `md:hidden ${data.length < 2 ? 'hidden' : 'flex'}` : 'flex'
				} items-center justify-center py-10 md:gap-4 gap-2 transition-all ease-in-out duration-300`}
			>
				<button
					type="button"
					onClick={movePrev}
					className="border-[#C4C4C4] text-[#C4C4C4] hover:text-brand-100 w-10 h-10  border-2 hover:border-brand-100 rounded-full items-center justify-center flex text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-10 w-10 "
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
					</svg>
					<span className="sr-only">Prev</span>
				</button>
				<button
					type="button"
					onClick={moveNext}
					className="border-[#C4C4C4] text-[#C4C4C4] hover:text-brand-100 w-10 h-10  border-2 hover:border-brand-100 rounded-full items-center justify-center flex  text-center opacity-75 hover:opacity-100 disabled:opacity-25 disabled:cursor-not-allowed z-10 p-0 m-0 transition-all ease-in-out duration-300"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="h-10 w-10"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						strokeWidth={2}
					>
						<path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
					</svg>
					<span className="sr-only">Next</span>
				</button>
			</div>
		</div>
	);
}
