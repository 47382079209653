import React from 'react';
import Carousel from './Carousel';

export default function CharityCarouselBlock({ data: charity }) {
	const charityLocations = [
		{
			id: 1,
			location: 'All',
			value: 'all',
		},
		{
			id: 2,
			location: 'United Kingdom',
			value: 'uk',
		},
		{
			id: 3,
			location: 'Republic of Ireland',
			value: 'ie',
		},
		{
			id: 4,
			location: 'Canada',
			value: 'ca',
		},
		{
			id: 5,
			location: 'Australia & New Zealand',
			value: 'nz',
		},
	];
	const [selectedLocation, setSelectedLocation] = React.useState(1);
	const [charityImages, setCharityImages] = React.useState([]);
	React.useEffect(() => {
		if (charity?.imageBlock) {
			setCharityImages([...charity.imageBlock, charity.charityLink]);
		}
	}, [charity]);
	const Component = 'div';

	const chooseLocation = (id) => {
		setSelectedLocation(id);
		setCharityImages(
			charity?.imageBlock.filter((el) => {
				if (id === 1) {
					return el;
				}
				return el.location === charityLocations[id - 1]?.value;
			})
		);
	};

	return (
		<div className="flex flex-col gap-4  w-full px-3  ">
			<div className="md:text-3xl text-xl font-semibold flex items-center justify-center">
				{charity?.header}
			</div>
			<div className="text-lg font-medium flex items-center justify-center">
				{charity?.subHeader}
			</div>
			<div className="flex gap-2 md:items-center md:justify-center flex-nowrap overflow-x-auto w-full no-scrollbar">
				{charityLocations.map((el) => (
					<Component
						key={el.id}
						onClick={() => chooseLocation(el.id)}
						className={`py-1 px-2 rounded border border-[#C4C4C4] cursor-pointer ${
							selectedLocation === el.id
								? 'bg-brand-100 text-white'
								: 'hover:bg-brand-100 hover:text-white'
						} `}
					>
						<span className="flex flex-nowrap truncate ">{el.location} </span>
					</Component>
				))}
			</div>
			<div className="2xl:container 2xl:mx-auto 2xl:px-0 py-3 px-1 md:flex md:items-center md:justify-center overflow-x-auto">
				<Carousel data={charityImages.filter((data) => data?.id !== undefined)} />
			</div>
		</div>
	);
}
